<template>
  <div class="topbar-item">
    <div
      class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
      id="kt_quick_user_toggle"
    >
      <span
        class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
      >
        Привет,
      </span>
      <span
        class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
      >
        {{ userInfo?.FirstName }}
      </span>
      <span class="symbol symbol-35 symbol-light-success">
        <div v-html="avatar"></div>
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">
          Профиль
          <!-- <small class="text-muted font-size-sm ml-2">12 messages</small> -->
        </h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <div v-html="avatar"></div>
            <i class="symbol-badge bg-success"></i>
          </div>
          <div class="d-flex flex-column">
            <span
              class="font-weight-bold font-size-h5 text-dark-75"
            >
              {{ getFullName }}
            </span>
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              Выйти
            </button>
          </div>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { LOGOUT } from '@/core/services/store/auth.module';
import KTLayoutQuickUser from '@/assets/js/layout/extended/quick-user.js';
import KTOffcanvas from '@/assets/js/components/offcanvas.js';
import { GET_USER_NAME } from '@/core/services/store/profile.module';

export default {
  name: 'KTQuickUser',
  data() {
    return {
      userInfo: {},
      list: [
        {
          title: 'Another purpose persuade',
          desc: 'Due in 2 Days',
          alt: '+28%',
          svg: '/media/svg/icons/Home/Library.svg',
          type: 'warning',
        },
        {
          title: 'Would be to people',
          desc: 'Due in 2 Days',
          alt: '+50%',
          svg: '/media/svg/icons/Communication/Write.svg',
          type: 'success',
        },
        {
          title: 'Purpose would be to persuade',
          desc: 'Due in 2 Days',
          alt: '-27%',
          svg: '/media/svg/icons/Communication/Group-chat.svg',
          type: 'danger',
        },
        {
          title: 'The best product',
          desc: 'Due in 2 Days',
          alt: '+8%',
          svg: '/media/svg/icons/General/Attachment2.svg',
          type: 'info',
        },
      ],
    };
  },
  async mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs['kt_quick_user']);
    await this.getUserInfo();
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: 'login' }));
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
    async getUserInfo() {
      let res = await this.$store.dispatch(GET_USER_NAME);
      this.userInfo = res?.data?.data;
    },
    firstLetter(word) {
      return word?.charAt(0);
    },
  },
  computed: {
    getFullName() {
      let res = '';
      let name = this.userInfo?.FirstName;
      if(name)
        res += name;
      let surname = this.userInfo?.LastName;
      if(surname)
        res += ' ' + surname;
      return res;
    },

    avatar() {
      let res = '';
      let name = this.userInfo?.FirstName;
      if(name) {
        res += this.firstLetter(name);
      }
      let surname = this.userInfo?.LastName;
      if(surname) {
        res += '' + this.firstLetter(surname);
      }
      res = `<div class="avatar-img">${res}</div>`;
      return res;
    },
  },
};
</script>

<style>
.avatar-img {
  width: 35px;
  height: 35px;
  line-height: 35px;
  background: #000;
  border-radius: 5px;
  color: #fff;
  font-size: 18px;
  white-space: nowrap;
  text-align: center;
}
.symbol.symbol-100 .avatar-img {
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 40px;
  font-weight: bold;
}
</style>
